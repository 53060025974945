$color1: #282828;
$color2: #212121;
$color3: #1e1e1e;

$normalBorderRadius: 10px;
$smallBorderRadius: 3px;

.archipelago {
    position: relative;
    border: 1px dashed #555;
    overflow: hidden;
    width: 700px;
    height: 500px;
}

.isle {
    position: absolute;
    border: 1pt solid $color2;
    border-radius: $normalBorderRadius $normalBorderRadius $smallBorderRadius $smallBorderRadius;
    overflow: hidden;

    .isle-content {
        background-color: $color3;
    }
 
    .isle-header {
        width: 100%;
        height: 25px;
        padding: 0;
        display: flex;
        overflow: hidden;
        background-image: linear-gradient($color1, $color2);
        color: white;

        .isle-header-left {
            background-color: #00aa00;
        }

        .isle-header-center {
            flex-grow: 1;
        }

        .isle-header-right {
            display: flex;

            > div {
                display: flex;
                align-items: center;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        svg {
            path {
                fill: white;
            }

            max-height: 100%;
            margin: 0;
            padding: 0;
            height: 18px;
        }
    }
}

@keyframes hiding-header {
    from {
        height: 25px;
    }
    to {
        height: 1px;
    }
}

.isle.hiding-frame {
    border: 0;
    
    .isle-header {
        animation: hiding-header 0.05s linear;
        height: 1px;
    }

    .isle-content {
        background: transparent;
    }
}

.isle.no-frame {
    border: 0;
    
    .isle-content {
        background: transparent;
    }
}